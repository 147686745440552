import React from "react";
import styled from "@emotion/styled";
import { useRecoilState } from "recoil";
import { graphql } from "gatsby";
import type { PageProps } from "gatsby";

import ArticlesPage from "../articles";
import ProjectsPage from "../projects";
import langAtom from "../../atoms/lang";
import Tag from "../../components/Tag";
import i18n from "../../utils/i18n";
import breakpoints from "../../utils/breakpoints";

const TagPageStyles = styled.div`
  margin: 1rem;

  .tag-display {
    max-width: ${breakpoints.values.lg}px;
    margin: auto;
    margin-bottom: 40px;

    & > div {
      margin: 0;

      h1 {
        font-size: 2rem;
      }
    }
  }
`;

type RelatedProject = {
  _type: "project";
  _createdAt: string;
  _updatedAt: string;
  _id: string;
  project_id: {
    current: string;
  };
  name: string;
  icon: string | null;
};

type RelatedArticle = {
  _type: "article";
  _createdAt: string;
  _updatedAt: string;
  _id: string;
  article_id: {
    current: string;
  };
  title: string;
  subtitle: string;
  image: string;
  lang: string;
  tags: {
    tag_id: {
      current: string;
    };
    label_en: string;
    label_ja: string;
  };
};

type DataProps = {
  sanityTag: {
    _createdAt: string;
    _updatedAt: string;
    _id: string;
    tag_id: {
      current: string;
    };
    related_content: (RelatedArticle | RelatedProject)[];
    label_en: string;
    label_ja: string;
  };
};

export default function TagPage(props: PageProps<DataProps>) {
  const tag = props.data.sanityTag;
  const [siteLang] = useRecoilState(langAtom);

  const articles = tag.related_content.filter((r) => r._type === "article");
  const projects = tag.related_content.filter((r) => r._type === "project");

  return (
    <TagPageStyles>
      <div className="tag-display">
        <h1>
          {i18n("tags.single")}:{" "}
          <Tag
            className="tag-card-tag"
            label={siteLang === "ja" ? tag.label_ja : tag.label_en}
            id={tag.tag_id.current}
          />
        </h1>
        {projects.length > 0 && (
          <ProjectsPage
            data={{
              allSanityProject: {
                nodes: projects as any[],
              },
            }}
          />
        )}
        {articles.length > 0 && (
          <ArticlesPage
            data={{
              allSanityArticle: {
                nodes: articles as any[],
              },
            }}
          />
        )}
      </div>
    </TagPageStyles>
  );
}

export const query = graphql`
  query ($id: String) {
    sanityTag(id: { eq: $id }) {
      _createdAt
      _updatedAt
      _id
      tag_id {
        current
      }
      related_content {
        ... on SanityArticle {
          _type
          _createdAt
          _updatedAt
          _id
          article_id {
            current
          }
          title
          subtitle
          image
          lang
          tags {
            tag_id {
              current
            }
            label_en
            label_ja
          }
        }
        ... on SanityProject {
          _type
          _createdAt
          _updatedAt
          _id
          project_id {
            current
          }
          name
          icon
        }
      }
      label_en
      label_ja
    }
  }
`;

export { default as Head } from "../../core/Head";
